import { Col, Div, Row } from "@/styles/layout";
import { Body } from "@/styles/typography";
import Avatar from "./Avatar";
import { FiHelpCircle, FiLogOut } from "react-icons/fi";
import Hoverable from "@/components/Hoverable";
import { useMe } from "@/hooks/useMe";
import { useAuth } from "@/hooks/auth.provider";

const ProfileMenu = () => {
  const { logout } = useAuth();
  const { user } = useMe();
  return (
    <Col alignItems={"stretch"} bg="primary.bgBase" boxShadow={"l"} m="3">
      <Row
        alignItems="center"
        borderBottomStyle={"solid"}
        py="16px"
        px="16px"
        borderColor="primary.borderSubtle"
      >
        <Avatar />
        <Col ml="16px">
          <Body fontSize="14px" fontWeight="600">
            {user?.firstName} {user?.lastName}
          </Body>
          <Body fontSize="14px" fontWeight="300">
            {user?.email}
          </Body>
        </Col>
      </Row>
      <a href="mailto:admin@gogive.com.au">
        <Row p="16px">
          <Div color="primary.textHigh" mr="16px">
            <FiHelpCircle size={18} />
          </Div>
          <Body color="primary.textHigh" fontSize="14px" fontWeight="400">
            Contact us
          </Body>
        </Row>
      </a>
      <Col
        p="16px"
        justifyContent={"space-between"}
        onClick={() => logout()}
        borderTopStyle={"solid"}
        borderColor="danger.borderSubtle"
      >
        <Hoverable>
          <Row style={{ cursor: "pointer" }} justifyContent={"space-between"}>
            <Body color="danger.textLow" fontSize="14px" fontWeight="400">
              Log out
            </Body>
            <Div color="danger.accent">
              <FiLogOut size={18} />
            </Div>
          </Row>
        </Hoverable>
      </Col>
    </Col>
  );
};

export default ProfileMenu;
