import React, { ReactNode, useState } from "react";
import { motion } from "framer-motion";

interface Props {
  children: ReactNode;
}

const Hoverable = ({ children }: Props) => {
  return (
    <motion.div
      initial={{ transform: "scale(1)" }}
      transition={{ type: "spring", duration: 0.3 }}
      whileHover={{ transform: `scale(0.98)` }}
      style={{ cursor: "pointer" }}
    >
      {children}
    </motion.div>
  );
};

export default Hoverable;
