import { themeGet } from "@styled-system/theme-get";
import { ReactNode } from "react";
import styled, { CSSProperties } from "styled-components";
import {
  typography,
  layout,
  space,
  color,
  border,
  flexbox,
  ShadowProps,
} from "styled-system";
import {
  SpaceProps,
  LayoutProps,
  TypographyProps,
  ColorProps,
} from "styled-system";

type TextProps = TypographyProps &
  SpaceProps &
  ColorProps &
  ShadowProps &
  LayoutProps & {
    as?: keyof JSX.IntrinsicElements;
    children?: ReactNode;
    style?: CSSProperties;
    dangerouslySetInnerHTML?: { __html: string };
  };

export const Body = styled.div<TextProps>`
  unset: all;
  line-height: 1.5;

  color: ${themeGet("colors.primary.textHigh")};

  letter-spacing: 0.05em;
  ${color}
  ${typography}
  ${space}
  ${layout}
  ${border}
  ${flexbox}
`;

export const Heading = styled.div<TextProps>`
  unset: all;
  line-height: 1.5;
  letter-spacing: 0.15rem;

  color: ${themeGet("colors.primary.textHigh")};

  ${color}
  ${typography}
  ${space}
  ${layout}
  ${border}
  ${flexbox}
`;

export const Span = styled.span<TextProps>`
  line-height: 1.5;

  user-select: none;
  color: ${themeGet("colors.primary.textHigh")};
  ${color} ${typography} ${space} ${layout} ${border}
    ${flexbox};
`;

export const Link = styled.a<TextProps>`
  ${space}
  ${color}
  ${layout}
  ${flexbox}
  ${border}
  
  text-underline-offset: 20%;
  &:hover {
    cursor: pointer;
  }
`;
