import { useAuth } from "@/hooks/auth.provider";
import { useMe } from "@/hooks/useMe";
import { Col } from "@/styles/layout";
import { Heading } from "@/styles/typography";

const Avatar = () => {
  const { authenticated } = useAuth();
  const { user } = useMe(!!authenticated);

  return (
    <Col
      justifyContent={"center"}
      alignItems={"center"}
      p="3"
      bg="primary.accent"
      borderRadius="50%"
      boxShadow={"l"}
    >
      {!!user && (
        <Heading fontSize="16px" fontWeight={600} color="primary.bgBase">
          {user?.firstName[0]}
          {user?.lastName[0]}
        </Heading>
      )}
    </Col>
  );
};

export default Avatar;
